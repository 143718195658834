import React, { useState, useEffect, useRef } from 'react';
import api from '../../../api';
import LoadingBar from '../../Ui/LoadingBar/LoadingBar';
import { compressAndConvertToWebP } from '../../../utils/imageprocessing';

/**
 * Manages voucher creation, editing, and deletion.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.cards - List of available cards.
 * @param {Array} props.companies - List of available companies.
 * @returns {React.ReactElement} Voucher management interface.
 */
function VoucherManagement({ cards, companies }) {
    const [vouchers, setVouchers] = useState([]);
    const [newVoucher, setNewVoucher] = useState({ code: '', description: '', discount: '', expiryDate: '', cardId: '', companyId: '' });
    const [editingVoucher, setEditingVoucher] = useState(null);
    const [voucherBackground, setVoucherBackground] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [error, setError] = useState('');
    const [loadingProgress, setLoadingProgress] = useState(0); // Add this line
    const [uploadError, setUploadError] = useState(''); // Add this line if not already present
    const fileInputRef = useRef(null);

    useEffect(() => {
        fetchVouchers();
    }, []);

    const fetchVouchers = async () => {
        try {
            const response = await api.getAllVouchers();
            console.log('Vouchers:', response.data); // Keep this line
            setVouchers(response.data);
        } catch (error) {
            console.error('Error fetching vouchers:', error);
        }
    };

    const handleCreateVoucher = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('code', newVoucher.code);
            formData.append('description', newVoucher.description);
            formData.append('discount', newVoucher.discount);
            formData.append('expiryDate', newVoucher.expiryDate);
            formData.append('cardId', newVoucher.cardId);
            formData.append('companyId', newVoucher.companyId);
            if (voucherBackground instanceof File) {
                formData.append('background', voucherBackground);
            }

            const response = await api.createVoucher(formData);
            setVouchers([...vouchers, response.data]);
            setNewVoucher({ code: '', description: '', discount: '', expiryDate: '', cardId: '', companyId: '' });
            setVoucherBackground(null);
            setPreviewImage(null);
            setError('');
        } catch (error) {
            console.error('Error creating voucher:', error.response ? error.response.data : error);
            setError('Failed to create voucher. Please try again.');
        }
    };

    const handleUpdateVoucher = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('code', newVoucher.code);
            formData.append('description', newVoucher.description);
            formData.append('discount', newVoucher.discount);
            formData.append('expiryDate', newVoucher.expiryDate);
            formData.append('cardId', newVoucher.cardId);
            formData.append('companyId', newVoucher.companyId);
            if (voucherBackground instanceof File) {
                formData.append('background', voucherBackground);
            }

            const response = await api.updateVoucher(editingVoucher._id, formData);
            setVouchers(vouchers.map(voucher => voucher._id === editingVoucher._id ? response.data : voucher));
            setNewVoucher({ code: '', description: '', discount: '', expiryDate: '', cardId: '', companyId: '' });
            setVoucherBackground(null);
            setPreviewImage(null);
            setEditingVoucher(null);
            setError('');
        } catch (error) {
            console.error('Error updating voucher:', error.response ? error.response.data : error);
            setError('Failed to update voucher. Please try again.');
        }
    };

    const handleEditVoucher = (voucher) => {
        setEditingVoucher(voucher);
        setNewVoucher({
            code: voucher.code,
            description: voucher.description,
            discount: voucher.discount,
            expiryDate: voucher.expiryDate.split('T')[0],
            cardId: voucher.card,
            companyId: voucher.company._id,
        });
        setVoucherBackground(null);
        setPreviewImage(voucher.backgroundImageUrl);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
          try {
            const processedFile = await compressAndConvertToWebP(file);
            setVoucherBackground(processedFile);
            setPreviewImage(URL.createObjectURL(processedFile));
          } catch (error) {
            console.error('Error processing voucher background:', error);
            setError('Error processing image. Please try again.');
          }
        }
      };

    const resetForm = () => {
        setEditingVoucher(null);
        setNewVoucher({ code: '', description: '', discount: '', expiryDate: '', cardId: '', companyId: '' });
        setVoucherBackground(null);
        setPreviewImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDeleteVoucher = async (voucherId) => {
        if (window.confirm('Are you sure you want to delete this voucher? This action cannot be undone.')) {
            try {
                await api.deleteVoucher(voucherId);
                setVouchers(vouchers.filter(voucher => voucher._id !== voucherId));
            } catch (error) {
                console.error('Error deleting voucher:', error);
                setError('Failed to delete voucher. Please try again.');
            }
        }
    };

    return (
        <div className="voucher-management">
            <div className="voucher-creation">
                <h2>{editingVoucher ? 'Edit Voucher' : 'Create New Voucher'}</h2>
                <form onSubmit={editingVoucher ? handleUpdateVoucher : handleCreateVoucher}>
                    <input
                        type="text"
                        placeholder="Voucher Code"
                        value={newVoucher.code}
                        onChange={(e) => setNewVoucher({ ...newVoucher, code: e.target.value })}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Description"
                        value={newVoucher.description}
                        onChange={(e) => setNewVoucher({ ...newVoucher, description: e.target.value })}
                        required
                    />
                    <input
                        type="number"
                        placeholder="Discount"
                        value={newVoucher.discount}
                        onChange={(e) => setNewVoucher({ ...newVoucher, discount: e.target.value })}
                        required
                    />
                    <input
                        type="date"
                        value={newVoucher.expiryDate}
                        onChange={(e) => setNewVoucher({ ...newVoucher, expiryDate: e.target.value })}
                        required
                    />
                    <select
                        value={newVoucher.cardId}
                        onChange={(e) => setNewVoucher({ ...newVoucher, cardId: e.target.value })}
                        required
                    >
                        <option value="">Select a card</option>
                        {Array.isArray(cards) && cards.map(card => (
                            <option key={card._id} value={card._id}>{card.name}</option>
                        ))}
                    </select>
                    <select
                        value={newVoucher.companyId}
                        onChange={(e) => setNewVoucher({ ...newVoucher, companyId: e.target.value })}
                        required
                    >
                        <option value="">Select a company</option>
                        {Array.isArray(companies) && companies.map(company => (
                            <option key={company._id} value={company._id}>{company.name}</option>
                        ))}
                    </select>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                    />
                    {previewImage && (
                        <img 
                            src={previewImage} 
                            alt="Voucher Background Preview" 
                            style={{ maxWidth: '200px', marginTop: '10px' }} 
                        />
                    )}
                    {loadingProgress > 0 && <LoadingBar progress={loadingProgress} />}
                    {uploadError && <p className="error-message">{uploadError}</p>}
                    <button type="submit">{editingVoucher ? 'Update Voucher' : 'Create Voucher'}</button>
                    {editingVoucher && (
                        <button type="button" onClick={resetForm}>Cancel Edit</button>
                    )}
                </form>
            </div>
            <div className="voucher-list">
                <h2>Existing Vouchers</h2>
                {vouchers.map(voucher => (
                    <div key={voucher._id} className="voucher-item">
                        <h3>{voucher.code}</h3>
                        <p>{voucher.description}</p>
                        <p>Discount: {voucher.discount}</p>
                        <p>Expiry: {new Date(voucher.expiryDate).toLocaleDateString()}</p>
                        <p>Card: {cards.find(card => card._id === voucher.card)?.name || 'N/A'}</p>
                        <p>Company: {
                            (typeof voucher.company === 'object' && voucher.company !== null)
                                ? voucher.company.name
                                : companies.find(company => company._id === voucher.company)?.name || 'N/A'
                        }</p>
                        {voucher.backgroundImageUrl && (
                            <img src={voucher.backgroundImageUrl} alt="Voucher Background" style={{ maxWidth: '100px' }} />
                        )}
                        <button onClick={() => handleEditVoucher(voucher)}>Edit</button>
                        <button onClick={() => handleDeleteVoucher(voucher._id)}>Delete</button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default VoucherManagement;