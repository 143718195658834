import React, { useState, useEffect, useRef } from 'react';
import api from '../../../api';
import { compressAndConvertToWebP } from '../../../utils/imageprocessing';

/**
 * Manages company creation, editing, and deletion.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.companies - List of existing companies.
 * @param {Function} props.setCompanies - Function to update the companies list.
 * @returns {React.ReactElement} Company management interface.
 */
function CompanyManagement({ companies, setCompanies }) {
  const [newCompany, setNewCompany] = useState({ name: '', description: '', location: '' });
  const [editingCompany, setEditingCompany] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await api.getAllCompanies();
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const handleCreateCompany = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', newCompany.name);
      formData.append('description', newCompany.description);
      formData.append('location', newCompany.location);
      if (companyLogo instanceof File) {
        formData.append('logo', companyLogo);
      }

      const response = await api.createCompany(formData);
      setCompanies([...companies, response.data]);
      resetForm();
    } catch (error) {
      console.error('Error creating company:', error.response ? error.response.data : error);
      setError('Failed to create company. Please try again.');
    }
  };

  const handleUpdateCompany = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', newCompany.name);
      formData.append('description', newCompany.description);
      formData.append('location', newCompany.location);
      if (companyLogo instanceof File) {
        formData.append('logo', companyLogo);
      }

      const response = await api.updateCompany(editingCompany._id, formData);
      setCompanies(companies.map(company => company._id === editingCompany._id ? response.data : company));
      resetForm();
    } catch (error) {
      console.error('Error updating company:', error.response ? error.response.data : error);
      setError('Failed to update company. Please try again.');
    }
  };

  const handleEditCompany = (company) => {
    setEditingCompany(company);
    setNewCompany({
      name: company.name,
      description: company.description,
      location: company.location
    });
    setCompanyLogo(null);
    setPreviewImage(company.logoUrl);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleDeleteCompany = async (companyId) => {
    if (window.confirm('Are you sure you want to delete this company? This action cannot be undone.')) {
      try {
        await api.deleteCompany(companyId);
        setCompanies(companies.filter(company => company._id !== companyId));
      } catch (error) {
        console.error('Error deleting company:', error);
        setError('Failed to delete company. Please try again.');
      }
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const processedFile = await compressAndConvertToWebP(file);
        setCompanyLogo(processedFile);
        setPreviewImage(URL.createObjectURL(processedFile));
      } catch (error) {
        console.error('Error processing company logo:', error);
        setError('Error processing image. Please try again.');
      }
    }
  };

  const resetForm = () => {
    setEditingCompany(null);
    setNewCompany({ name: '', description: '', location: '' });
    setCompanyLogo(null);
    setPreviewImage(null);
    setError('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="company-management">
      <div className="company-creation">
        <h2>{editingCompany ? 'Edit Company' : 'Create New Company'}</h2>
        <form onSubmit={editingCompany ? handleUpdateCompany : handleCreateCompany}>
          <input
            type="text"
            placeholder="Company Name"
            value={newCompany.name}
            onChange={(e) => setNewCompany({ ...newCompany, name: e.target.value })}
            required
          />
          <textarea
            placeholder="Company Description"
            value={newCompany.description}
            onChange={(e) => setNewCompany({ ...newCompany, description: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Company Location"
            value={newCompany.location}
            onChange={(e) => setNewCompany({ ...newCompany, location: e.target.value })}
            required
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          {previewImage && (
            <img
              src={previewImage}
              alt="Company logo preview"
              style={{ maxWidth: '200px' }}
            />
          )}
          <button type="submit">{editingCompany ? 'Update Company' : 'Create Company'}</button>
          {editingCompany && (
            <button type="button" onClick={resetForm}>Cancel Edit</button>
          )}
        </form>
      </div>
      <div className="company-list">
        <h2>Existing Companies</h2>
        {Array.isArray(companies) && companies.length > 0 ? (
          companies.map(company => (
            <div key={company._id} className="company-item">
              <h3>{company.name}</h3>
              <p>{company.description}</p>
              <p>Location: {company.location}</p>
              {company.logoUrl && (
                <img
                  src={company.logoUrl}
                  alt={`${company.name} logo`}
                  style={{ maxWidth: '100px', maxHeight: '100px' }}
                />
              )}
              <button onClick={() => handleEditCompany(company)}>Edit</button>
              <button onClick={() => handleDeleteCompany(company._id)}>Delete</button>
            </div>
          ))
        ) : (
          <p>No companies available. Create a new company to get started.</p>
        )}
      </div>
    </div>
  );
}

export default CompanyManagement;