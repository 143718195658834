import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

/**
 * Represents the footer component of the application.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className=''] - Additional CSS class for the footer.
 * @returns {React.ReactElement} A footer element with navigation links.
 */
function Footer({ className = '' }) {
  return (
    <div className={`footer ${className}`}>
      <Link to="/contact">Contact Us</Link>
      <Link to="/about">About Us</Link>
      <Link to="/work-with-us">Work with Us</Link>
      <span>&copy; 2023 Small Potatoes</span>
    </div>
  );
}

export default Footer;