import { useLanguage } from '../Translations/LanguageContext';
import { translations } from '../Translations/translations';

/**
 * Custom hook for accessing translations.
 * 
 * @returns {Object} An object containing the current language and translation function.
 */
export const useTranslation = () => {
  const { language } = useLanguage();
  const t = translations[language];

  return { language, t };
};