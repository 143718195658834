import React from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';

/**
 * Route component that checks if the user is an organizer.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render if the user is an organizer.
 * @returns {React.ReactElement} The children if the user is an organizer, otherwise redirects to the landing page.
 */
function OrganizerRoute({ children }) {
  const { user } = useOutletContext();

  if (!user || !user.isOrganizer) {
    return <Navigate to="/landing" replace />;
  }

  return children;
}

export default OrganizerRoute;