/**
 * Contains translations for different languages used in the application.
 *
 * @constant
 * @type {Object.<string, Object.<string, string>>}
 */
export const translations = {
    en: {
      profile: "Profile",
      settings: "Settings",
      login: "Login",
      logout: "Logout",
      noCards: "No cards available",
      vouchers: "Vouchers",
      tickets: "Tickets",
      noVouchers: "No vouchers available",
      redeem: "Redeem",
      adminDashboard: "Admin Dashboard",
      mobile: "Mobile",
      save: "Save",  
      yourCards: "Your Cards",
      moreVouchersComingSoon: "More vouchers coming soon",
      moreEventsComingSoon: "More events coming soon!",
      noTickets: "No tickets available",
      currencyConversions: "Currency conversions will be applied at checkout",
      // Add more translations here
    },
    zh: {
      profile: "个人资料",
      settings: "设置",
      login: "登录",
      logout: "登出",
      noCards: "没有可用的卡",
      vouchers: "优惠券",
      tickets: "票",
      noVouchers: "没有可用的优惠券",
      redeem: "兑换",
      adminDashboard: "管理员仪表板",
      mobile: "手机号码",
      save: "保存",
      yourCards: "你的卡片",
      moreVouchersComingSoon: "更多优惠券即将到来",
      moreEventsComingSoon: "更多活动即将推出！",
      noTickets: "没有可用的票",
      currencyConversions: "货币转换将在结账时应用",

            // Add more translations here
    }
  };