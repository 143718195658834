import React, { useState, useEffect } from 'react';
import './AddToHomeScreenPrompt.css';

/**
 * Displays a prompt for users to add the app to their home screen or favorites.
 * The prompt adapts based on the user's browser and device type.
 *
 * @component
 * @returns {React.ReactElement|null} The prompt element or null if not shown.
 */
const AddToHomeScreenPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [browserType, setBrowserType] = useState('');

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isIOSChrome = /CriOS/i.test(navigator.userAgent) && /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isWeChat = /MicroMessenger/i.test(navigator.userAgent);

    if (!isStandalone && isMobileDevice && !localStorage.getItem('addToHomeScreenPromptDismissed')) {
      setShowPrompt(true);
      if (isIOSChrome) {
        setBrowserType('iosChrome');
      } else if (isWeChat) {
        setBrowserType('weChat');
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        setBrowserType('ios');
      } else {
        setBrowserType('android');
      }

    }


  }, []);

  useEffect(() => {
    if (showPrompt) {
      localStorage.setItem('addToHomeScreenPromptDismissed', 'true');
    }
  }, [showPrompt]);

  const handleClose = () => {
    setShowPrompt(false);
  };

  if (!showPrompt) return null;



  return (
    <div className="add-to-home-screen-prompt">
      <h2>
        {browserType === 'iosChrome' ? 'Like The App?' : 'Install Our App'}
      </h2>
      <p>
        {browserType === 'iosChrome'
          ? "Give us a favorite for quick and easy access!"
          : "Get quick access and a better experience by adding our app to your home screen!"}
      </p>
      
      {browserType === 'iosChrome' && (
        <div className="ios-chrome-instructions">
          <p className="instruction-title">To favorite on iOS Chrome:</p>
          <ol>
            <li>Tap the <strong>More</strong> button<span className="icon">&#x2630;</span> in your browser</li>
            <li>Select <strong>Add to Favorites</strong></li>
            <li>Choose where to save the favorite and tap <strong>Save</strong></li>
          </ol>
        </div>
      )}

      {browserType === 'weChat' && (
        <div className="wechat-instructions">
          <p className="instruction-title">To add to floating on WeChat:</p>
          <ol>
            <li>Tap the <strong>Menu</strong> button<span className="icon">&#x2630;</span> in WeChat</li>
            <li>Select <strong>Add to Floating</strong></li>
          </ol>
        </div>
      )}

      {browserType === 'ios' && (
        <div className="ios-instructions">
          <p className="instruction-title">To install on iOS:</p>
          <ol>
            <li>Tap the <strong>Share</strong> button <span className="icon">&#x1F4E2;</span> in your browser</li>
            <li>Scroll down and tap <strong>Add to Home Screen</strong></li>
            <li>Tap <strong>Add</strong> in the top right corner</li>
          </ol>
        </div>
      )}

      {browserType === 'android' && (
        <div className="android-instructions">
          <p className="instruction-title">To install on Android:</p>
          <ol>
            <li>Tap the <strong>Menu</strong> button <span className="icon">&#x2630;</span> in your browser</li>
            <li>Select <strong>Add to Home screen</strong></li>
            <li>Tap <strong>Add</strong> to confirm</li>
          </ol>
        </div>
      )}
      
      <button onClick={handleClose} className="dismiss-button">Maybe Later</button>
    </div>
  );
};

export default AddToHomeScreenPrompt;