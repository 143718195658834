import React, { useState, useRef, useEffect } from 'react';
import './VoucherDisplay.css';
import potatoImage from '../../images/potato.png';

/**
 * Represents a voucher display component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.voucher - The voucher object containing discount and company details.
 * @param {Function} props.onClose - Function to handle closing the voucher display.
 * @returns {React.ReactElement} A voucher display element with company logo, discount details, and terms.
 */
function VoucherDisplay({ voucher, onClose }) {
  const [termsExpanded, setTermsExpanded] = useState(false);
  const termsContentRef = useRef(null);

  const toggleTerms = () => {
    setTermsExpanded(prevState => !prevState);
  };

  useEffect(() => {
    if (termsExpanded) {
      termsContentRef.current.style.maxHeight = `${termsContentRef.current.scrollHeight}px`;
      setTimeout(() => {
        termsContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 120); // Adjust the timeout duration if needed
    } else {
      termsContentRef.current.style.maxHeight = '0';
    }
  }, [termsExpanded]);

  return (
    <>
      <div className="voucher-display-overlay" onClick={onClose}></div>
      <div className="voucher-display" onClick={(e) => e.stopPropagation()}>
   
        <div className="voucher-display-header">     
          <button className="voucher-display-close-button" onClick={onClose}>×</button>
          <h2>My Voucher</h2>
        </div>
        <div className="voucher-display-content">
          <img 
            src={voucher.company.logoUrl} 
            alt={`${voucher.company.name} logo`} 
            className="voucher-display-logo" 
          />
          <h3 className="voucher-display-title">{voucher.company.name}</h3>
          <div className="voucher-display-discount">{voucher.discount}% OFF</div>
          <ul className="voucher-display-details">
            <li>Redeemable at all {voucher.company.name} locations</li>
            <li>Not applied for public holidays</li>
            <li>No cash value</li>
          </ul>
          <div className="voucher-display-divider"></div>
          <div className="voucher-display-qr">
            <img src={potatoImage} alt="Potato QR Code" className="voucher-display-potato-qr" />
          </div>
          <p className={`voucher-display-terms ${termsExpanded ? 'expanded' : ''}`} onClick={toggleTerms}>
            Terms and conditions applied
          </p>
          <div ref={termsContentRef} className={`voucher-display-terms-content ${termsExpanded ? 'expanded' : ''}`}>
            <p>{voucher.description}</p>
            <p>Here are the detailed terms and conditions...</p>            
            <p>Here are the detailed terms and conditions...</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default VoucherDisplay;