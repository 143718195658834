import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';
import api from '../../api';
import './Settings.css';
import Header from '../Header/Header';

// Lazy load the AuthPopup component
const AuthPopup = lazy(() => import('../AuthPopup/AuthPopup'));


/**
 * Represents the Settings page component.
 *
 * @component
 * @returns {React.ReactElement} The Settings page UI with language selection and user options.
 */
function SettingsPage() {
  const { user, handleLogout, language, setLanguage, handleLoginSuccess: contextHandleLoginSuccess } = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showAuthPopup, setShowAuthPopup] = useState(false);

  const isFromLandingPage = location.state?.fromLandingPage;

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      if (user) {
        try {
          const response = await api.get('/users/phone');
          setPhoneNumber(response.data.phone || '');
        } catch (error) {
          console.error('Error fetching phone number:', error);
          setPhoneNumber('');
        }
      }
    };
    fetchPhoneNumber();
  }, [user]);

  const handleSave = () => {
    setLanguage(selectedLanguage);
    navigate(user ? '/' : '/landing');
  };

  const handleLogin = () => {
    setShowAuthPopup(true);
  };

  const handleCloseAuthPopup = () => {
    setShowAuthPopup(false);
  };

  const handleLoginSuccess = (userData) => {
    contextHandleLoginSuccess(userData);
    setShowAuthPopup(false);
    navigate('/');
  };

  return (
    <div className="settings-page">
      <Header user={user} />
      <div className="settings-content">
        <main className="settings-main">
          <div className="settings-container">
            {user && (
              <div className="user-info">
                <div className="avatar">😎</div>
                <h2 className="user-name">{user.name}</h2>
              </div>
            )}
            <div className="settings-form">
              {user && (
                <div className="form-group">
                  <label htmlFor="mobile">{t.mobile}</label>
                  <input type="text" id="mobile" value={phoneNumber} readOnly />
                </div>
              )}
              <div className="form-group">
                <label htmlFor="language">{t.language}</label>
                <select
                  id="language"
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                >
                  <option value="en">English</option>
                  <option value="zh">中文</option>
                </select>
              </div>
            </div>
            <div className="settings-actions">
              <button className="save-button" onClick={handleSave}>{t.save}</button>
              {!user ? (
                <button className="login-button" onClick={handleLogin}>{t.login}</button>
              ) : (
                <button className="logout-button" onClick={handleLogout}>{t.logout}</button>
              )}
            </div>
          </div>
        </main>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {showAuthPopup && (
          <AuthPopup
            mode="login"
            onClose={handleCloseAuthPopup}
            onLoginSuccess={handleLoginSuccess}
          />
        )}
      </Suspense>
    </div>
  );
}

export default SettingsPage;