import { loadStripe } from '@stripe/stripe-js';

let stripePromise = null;

/**
 * Initializes and returns a Stripe promise with the publishable key.
 *
 * @async
 * @function
 * @returns {Promise<Stripe>} A promise that resolves to a Stripe instance.
 * @throws {Error} If the Stripe publishable key is not available.
 */
const getStripePromise = async () => {
  if (!stripePromise) {
    const response = await fetch('/api/payments/stripe-key');
    const { publishableKey } = await response.json();
    if (!publishableKey) {
      throw new Error('Stripe publishable key is not available');
    }
    stripePromise = loadStripe(publishableKey, {
      apiVersion: '2024-06-20',
      locale: 'auto',
    });
  }
  return stripePromise;
};

export default getStripePromise;