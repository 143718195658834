import React, { useState, useEffect } from 'react';
import { useParams, useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import api from '../../api';
import './EventPage.css';
import Checkout from './Purchase/Checkout/Checkout';
import Basket from './Purchase/Basket';
import AuthPopup from '../AuthPopup/AuthPopup';
import ShareIcon from '../../images/share.svg';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';
import DOMPurify from 'dompurify';


const customSchema = {
  tagNames: ['h1', 'h2', 'h3', 'p', 'strong', 'em', 'a', 'ul', 'ol', 'li', 'hr'],
  attributes: {
    a: ['href'],
    '*': ['className']
  },
  allowComments: false,
  allowElements: ['h1', 'h2', 'h3', 'p', 'strong', 'em', 'a', 'ul', 'ol', 'li', 'hr'],
  protocols: {
    href: ['http', 'https', 'mailto']
  },
  transformUrls: {
    // This function will be called for each URL
    '*': (url) => {
      // You can implement additional URL sanitization here
      return url;
    }
  }
};
const formatDateTime = (dateTimeString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };
  const formatted = new Date(dateTimeString).toLocaleString('en-US', options);
  return formatted.replace(/(\d+:\d+)\s(AM|PM)/, '$1\u00A0$2');
};

/**
 * Represents the Event Page component.
 * Displays detailed information about a specific event, including ticket types and purchase options.
 *
 * @component
 * @returns {React.ReactElement} The Event Page UI.
 */
const EventPage = () => {
  const { user, handleLoginSuccess } = useOutletContext();
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [basketItems, setBasketItems] = useState([]);
  const [showCheckout, setShowCheckout] = useState(false);
  const [showBasket, setShowBasket] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [authMessage, setAuthMessage] = useState('');
  const [availableTickets, setAvailableTickets] = useState({});
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const location = useLocation();
  const [referralCode, setReferralCode] = useState(null);
  const [formattedDescription, setFormattedDescription] = useState('');

  const updateQuantity = (itemId, newQuantity) => {
    setBasketItems(prevItems => {
      const updatedItems = prevItems.map(item => {
        if (item.id === itemId) {
          const availableCount = availableTickets[itemId];
          const oldQuantity = item.quantity;
          const updatedQuantity = Math.min(Math.max(1, newQuantity), availableCount + oldQuantity);

          // Update available tickets
          setAvailableTickets(prev => ({
            ...prev,
            [itemId]: prev[itemId] + oldQuantity - updatedQuantity
          }));

          return { ...item, quantity: updatedQuantity };
        }
        return item;
      });
      return updatedItems;
    });
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        console.log('Link copied to clipboard');
        setShowCopyNotification(true);
        setTimeout(() => setShowCopyNotification(false), 3000); // Hide after 3 seconds
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
      });
  };;


  const removeItem = (itemId) => {
    setBasketItems(prevItems => {
      const removedItem = prevItems.find(item => item.id === itemId);
      const updatedItems = prevItems.filter(item => item.id !== itemId);

      if (removedItem) {
        // Update available tickets
        setAvailableTickets(prev => ({
          ...prev,
          [itemId]: prev[itemId] + removedItem.quantity
        }));
      }

      if (updatedItems.length === 0) {
        setShowBasket(false);
        document.body.classList.remove('no-scroll');
        document.body.style.top = '';
        window.scrollTo(0, scrollPosition);
      }

      return updatedItems;
    });
  };

  const applyPromoCode = (code) => {
    console.log('Applying promo code:', code);
  };

  const addToBasket = (ticket) => {
    const availableCount = availableTickets[ticket._id];

    console.log('Available count:', availableCount);
    if (availableCount <= 0) {
      console.log('Ticket is sold out');
      return;
    }

    setBasketItems(prevItems => {
      const existingItem = prevItems.find(item => item.id === ticket._id);
      console.log('Existing item:', existingItem);
      if (existingItem) {
        if (availableCount < 1) {
          console.log('No more tickets available');
          return prevItems;
        }
        console.log('updating');
        return prevItems.map(item =>
          item.id === ticket._id
            ? { ...item, quantity: Math.min(item.quantity + 1) }
            : item
        );
      } else {

        console.log('fiewd');
        return [...prevItems, { ...ticket, id: ticket._id, quantity: 1 }];
      }


    });
    // Update available tickets
    setAvailableTickets(prev => ({

      ...prev,
      [ticket._id]: prev[ticket._id] - 1
    }));
  };

  const handleCheckout = () => {
    if (!user) {
      setAuthMessage('Please log in or create an account to purchase tickets.');
      setShowAuthPopup(true);
      setShowBasket(false);
    } else {
      setShowCheckout(true);
      setShowBasket(false);
      // Pass referral code to Checkout component
      localStorage.setItem('referralCode', referralCode);
    }

    document.body.classList.remove('no-scroll');
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('top');
    window.scrollTo(0, scrollPosition);
  };

  const handleAuthSuccess = (userData) => {
    handleLoginSuccess(userData);
    setShowAuthPopup(false);
    setShowBasket(true);  // Show basket after successful login
  };

  const toggleBasket = () => {
    if (!user) {
      setAuthMessage('Please log in or create an account to view your basket.');
      setShowAuthPopup(true);
      return;
    }
    if (!showBasket) {
      setScrollPosition(window.scrollY);
      document.body.style.top = `-${window.scrollY}px`;
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      window.scrollTo(0, scrollPosition);
      document.body.style.top = '';
    }
    setShowBasket(!showBasket);
  };

  const basketItemCount = basketItems.reduce((sum, item) => sum + item.quantity, 0);

  const openTicketPopup = (ticket) => {
    setScrollPosition(window.scrollY);
    document.body.style.top = `-${window.scrollY}px`;
    setSelectedTicket(ticket);
    document.body.classList.add('no-scroll');
  };

  const closeTicketPopup = () => {
    setSelectedTicket(null);
    document.body.classList.remove('no-scroll');
    window.scrollTo(0, scrollPosition);
    document.body.style.top = '';
  };

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await api.getEventById(id);
        setEvent(response.data);

        // Inside your component
        const sanitizedDescription = DOMPurify.sanitize(response.data.description);
        setFormattedDescription(sanitizedDescription);
        // Initialize available tickets
        const ticketAvailability = {};
        response.data.ticketTypes.forEach(tt => {
          ticketAvailability[tt._id] = tt.quantity - tt.sold;
        });

        setAvailableTickets(ticketAvailability);

        // Check for referral code in URL
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('ref');
        if (code) {
          setReferralCode(code);
          // Track referral click
          try {
            await api.trackReferralClick(code);
            console.log('Referral click tracked successfully');
          } catch (error) {
            console.error('Error tracking referral click:', error);
          }
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };
    fetchEvent();
  }, [id, location]);

  useEffect(() => {
    if (showBasket || selectedTicket) {
      document.body.classList.add('no-scroll');
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.pageYOffset}px`;
    } else {
      document.body.classList.remove('no-scroll');
      const scrollY = document.body.style.top;
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('top');
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }

    return () => {
      // Cleanup function to ensure body scroll is restored when component unmounts
      document.body.classList.remove('no-scroll');
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('top');
    };
  }, [showBasket, selectedTicket]);

  if (!event) {
    return <div>Loading...</div>;
  }

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % event.images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + event.images.length) % event.images.length);
  };

  return (
    <div className="event-page">
      <Header user={user} />
      {showCheckout ? (
        <Checkout
          total={basketItems.reduce((sum, item) => sum + item.price * item.quantity, 0)}
          items={basketItems}
          event={event}
        />
      ) : (
        <>
          <div className="event-carousel">
            {event.images.map((image, index) => (
              <div
                key={index}
                className={`carousel-slide ${index === currentSlide ? 'active' : ''}`}
              >
                <img src={image} alt={`Event Image ${index + 1}`} className="carousel-image" />
              </div>
            ))}
            <button className="carousel-button prev" onClick={prevSlide}>&lt;</button>
            <button className="carousel-button next" onClick={nextSlide}>&gt;</button>
          </div>
          <div className="event-content">
            <div className="event-details">
              <div className="event-title-container">
                <h1 className="event-title">{event.title}</h1>
                <button className="share-button" onClick={handleShare}>
                  <img src={ShareIcon} alt="Share" />
                </button>
                {showCopyNotification && (
                  <div className="copy-notification">Link copied to clipboard!</div>
                )}
              </div>
              <div className="event-description-container">
              <ReactMarkdown
                rehypePlugins={[[rehypeSanitize, customSchema]]}
                components={{
                  h1: ({ node, ...props }) => <h1 className="event-description-h1" {...props} />,
                  h2: ({ node, ...props }) => <h2 className="event-description-h2" {...props} />,
                  h3: ({ node, ...props }) => <h3 className="event-description-h3" {...props} />,
                  p: ({ node, ...props }) => <p className="event-description-p" {...props} />,
                  strong: ({ node, ...props }) => <strong className="event-description-strong" {...props} />,
                  em: ({ node, ...props }) => <em className="event-description-em" {...props} />,
                  a: ({ node, ...props }) => <a className="event-description-link" target="_blank" rel="noopener noreferrer" {...props} />,
                  ul: ({ node, ...props }) => <ul className="event-description-ul" {...props} />,
                  ol: ({ node, ...props }) => <ol className="event-description-ol" {...props} />,
                  li: ({ node, ...props }) => <li className="event-description-li" {...props} />,
                  hr: ({ node, ...props }) => <hr className="event-description-hr" {...props} />,
                }}
              >
                {formattedDescription}
              </ReactMarkdown>
              </div>
              <div className="event-info-grid">
                <EventInfoItem
                  icon={<CalendarIcon />}
                  title="Date & Time"
                  description={formatDateTime(event.dateTime)}
                />
                <EventInfoItem
                  icon={<LocationIcon />}
                  title="Location"
                  description={event.location}
                />
                <EventInfoItem
                  icon={<PriceIcon />}
                  title="Price Range"
                  description={event.priceRange}
                />
                <EventInfoItem
                  icon={<ClockIcon />}
                  title="Last Entry"
                  description={event.lastEntry}
                />
                <EventInfoItem
                  icon={<AgeIcon />}
                  title="Age Restriction"
                  description={event.ageRestriction}
                />
                {basketItemCount > 0 && (
                  <div className="basket-section">
                    <button className="open-basket-button" onClick={toggleBasket}>
                      Open Basket ({basketItemCount})
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="event-sidebar">
              <div className="vendor-info">
                <div className="info-title">Vendor Info</div>
                <div className="info-description">{event.vendor}</div>
              </div>
              <div className="ticket-types">
                <div className="info-title">Ticket Types:
                  <div className="ticket-booking-fee">Booking Fee: £1 or 10% whichever is greater</div></div>
                {event.ticketTypes && event.ticketTypes.length > 0 ? (
                  <div className="ticket-list">
                    {event.ticketTypes.map((ticket) => (
                      <TicketCard
                        key={ticket._id}
                        id={ticket._id}
                        title={ticket.title}
                        description={ticket.description}
                        price={ticket.price}
                        onClick={() => openTicketPopup(ticket)}
                        onAddToBasket={() => addToBasket(ticket)}
                        isSoldOut={availableTickets[ticket._id] <= 0}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="no-tickets-message">
                    <p>Tickets coming soon!</p>
                    <p>Check back later for updates on ticket availability.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {basketItemCount > 0 && (
            <button className="floating-basket-button" onClick={toggleBasket}>
              Basket ({basketItemCount})
            </button>
          )}

          {showBasket && user && basketItems.length > 0 && (
            <Basket
              items={basketItems}
              updateQuantity={updateQuantity}
              removeItem={removeItem}
              onCheckout={handleCheckout}
              onClose={toggleBasket}
              availableTickets={availableTickets}
            />
          )}

          {selectedTicket && (
            <TicketPopup
              ticket={selectedTicket}
              onClose={closeTicketPopup}
              onAddToBasket={() => {
                if (user) {
                  addToBasket(selectedTicket);
                  closeTicketPopup();
                } else {
                  setAuthMessage('Please log in or create an account to add tickets to your basket.');
                  setShowAuthPopup(true);
                  closeTicketPopup();
                }
              }}
            />
          )}

          {showAuthPopup && (
            <AuthPopup
              mode="login"
              onClose={() => {
                setShowAuthPopup(false);
                setAuthMessage('');
              }}
              onLoginSuccess={handleAuthSuccess}
              message={authMessage}
            />
          )}
        </>
      )}
    </div>
  );
};

const EventInfoItem = ({ icon, title, description }) => (
  <div className="event-info-item">
    {icon}
    <div>
      <div className="info-title">{title}</div>
      <div className="info-description">{description}</div>
    </div>
  </div>
);

const TicketCard = ({ id, title, description, price, color, onClick, onAddToBasket, isSoldOut }) => (
  <div className="ticket-card" style={{ backgroundColor: color || '#FFFFFF' }}>
    <div className="ticket-info">
      <div className="ticket-header">
        <div className="ticket-title">{title}</div>
        <div className="ticket-price">£{parseFloat(price).toFixed(2)}</div>
      </div>

      <div className="ticket-description">{description.slice(0, 50)}...</div>
    </div>
    <div className="ticket-buttons">
      <button
        className="ticket-button view-details"
        onClick={onClick}
      >
        View Details
      </button>
      <button
        className="ticket-button add-to-basket"
        onClick={() => onAddToBasket({ id, title, description, price, color })}
        disabled={isSoldOut}
      >
        {isSoldOut ? 'Sold Out' : 'Add to Basket'}
      </button>
    </div>
  </div>
);

const TicketPopup = ({ ticket, onClose, onAddToBasket }) => {
  const popupRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="ticket-popup-overlay">
      <div className="ticket-popup" ref={popupRef}>
        <h2>{ticket.title}</h2>
        <p className="ticket-popup-price">£{parseFloat(ticket.price).toFixed(2)}</p>
        <p className="ticket-popup-description">{ticket.description}</p>
        <div className="ticket-popup-buttons">
          <button onClick={onAddToBasket}>Add to Basket</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

// Icon components (simplified for brevity)
const CalendarIcon = () => <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M8 2v4"></path><path d="M16 2v4"></path><rect width="18" height="18" x="3" y="4" rx="2"></rect><path d="M3 10h18"></path><path d="M8 14h.01"></path><path d="M12 14h.01"></path><path d="M16 14h.01"></path><path d="M8 18h.01"></path><path d="M12 18h.01"></path><path d="M16 18h.01"></path></svg>;
const LocationIcon = () => <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path><circle cx="12" cy="10" r="3"></circle></svg>;
const PriceIcon = () => <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>;
const ClockIcon = () => <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>;
const AgeIcon = () => <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M22 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>;

export default EventPage;