import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useNavigate, useOutletContext, Link, useLocation } from 'react-router-dom';
import './LandingPage.css';
import placeholderImage from '../../images/placeholder.webp';
import Header from '../Header/Header';
import TermsAndConditions from '../Register/TermsAndConditions';
import api from '../../api';

// Lazy load the AuthPopup component
const AuthPopup = lazy(() => import('../AuthPopup/AuthPopup'));

/**
 * Represents the landing page component of the application.
 *
 * @component
 * @returns {React.ReactElement} The landing page UI with featured vouchers, join community section, and partners.
 */
const LandingPage = () => {
  const { user, handleLoginSuccess: contextHandleLoginSuccess } = useOutletContext();
  const [landingData, setLandingData] = useState(null);
  const [error, setError] = useState(null);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [authMode, setAuthMode] = useState(null);
  const navigate = useNavigate();
  const [showTerms, setShowTerms] = useState(false);
  const location = useLocation();
  const [featuredEvent, setFeaturedEvent] = useState(null);

  useEffect(() => {
    if (user) {
      navigate('/');
      return;
    }

    const fetchLandingData = async () => {
      try {
        const response = await api.getLandingPageData();
        setLandingData(response.data);
      } catch (error) {
        console.error('Error fetching landing page data:', error);
        setError('Failed to load landing page data. Please try again later.');
        setLandingData({
          vouchers: [],
          companies: [],
          howItWorks: ['Step 1', 'Step 2', 'Step 3']
        });
      }
    };

    const fetchFeaturedEvent = async () => {
      try {
        const response = await api.getAllEvents();
        if (response.data && response.data.length > 0) {
          console.log(response.data[0]);
          setFeaturedEvent(response.data[0]);
        } else {
          console.log('No featured event found');
          setFeaturedEvent(null);
        }
      } catch (error) {
        console.error('Error fetching featured event:', error);
        setFeaturedEvent(null);
      }
    };

    fetchLandingData();
    fetchFeaturedEvent();
  }, [user, navigate]);

  useEffect(() => {
    if (showAuthPopup || showTerms) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    }
  }, [showAuthPopup, showTerms]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('openLogin') === 'true') {
      setShowAuthPopup(true);
      setAuthMode('login');
    }
  }, [location]);

  const handleGetStarted = () => {
    setAuthMode('register');
    setShowAuthPopup(true);
  };

  const handleLogin = () => {
    setAuthMode('login');
    setShowAuthPopup(true);
  };

  const handleCloseAuthPopup = () => {
    setShowAuthPopup(false);
  };

  const handleLoginSuccess = (userData, token) => {
    contextHandleLoginSuccess(userData);
    setShowAuthPopup(false); // Close the AuthPopup after successful login
    navigate('/');
  };

  const handleOpenTerms = (e) => {
    e.preventDefault();
    console.log("Opening terms");
    setShowTerms(true);
  };

  /**
   * Represents a voucher card component.
   *
   * @component
   * @param {Object} props - The component props.
   * @param {Object} props.voucher - The voucher object to display.
   * @returns {React.ReactElement} A voucher card element.
   */
  const VoucherCard = ({ voucher }) => {
    const companyName = voucher.company.name || 'Company';
    const companyLogo = voucher.company.logoUrl;

    return (
      <div className="voucher-card">
        <div className="voucher-image">
          <img 
            src={voucher.backgroundImageUrl || placeholderImage} 
            alt="Voucher background" 
          />
          <div className="company-logo">
            <img 
              src={companyLogo}
              alt={`${companyName} logo`}
            />
          </div>
        </div>
        <div className="voucher-content">
          <h4>{voucher.description}</h4>
          <p className="discount">{voucher.discount}% OFF</p>
          <p className="details">Expires: {new Date(voucher.expiryDate).toLocaleDateString()}</p>
        </div>
      </div>
    );
  };

  if (user) return null; // Return null while redirecting
  if (error) return <div className="error-message">{error}</div>;
  if (!landingData) return <div>Loading...</div>;

  return (
    <div className={`landing-page ${showAuthPopup ? 'auth-popup-active' : ''}`}>
      <Header />
      <section className="advertise-events">
        <div className="content-wrapper">
          <h2>Discover Events</h2>
          <p>We offer tickets to a variety of events that you can attend. Check out upcoming events and get your tickets now!</p>
          <div className="event-content">
            {featuredEvent ? (
              <div className="advertise-featured-event">
                <div className="advertise--featured-event-wrapper">
                <Link to={`/events/${featuredEvent.slug}`} className="advertise-featured-event-link">
                  <img 
                    src={featuredEvent.images && featuredEvent.images.length > 0 ? featuredEvent.images[0] : placeholderImage} 
                    alt={featuredEvent.title} 
                    className="advertise-featured-event-image" 
                  />
                  <div className="advertise-featured-event-content">
                    <h2 className="advertise-featured-event-title">{featuredEvent.title || 'Upcoming Event'}</h2>
<p  className="event-description">{featuredEvent.shortDescription ? `${featuredEvent.shortDescription}...` : 'Event details coming soon...'}</p>
                    <div className="event-details">
                      <div className="event-date">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                          <line x1="16" y1="2" x2="16" y2="6"></line>
                          <line x1="8" y1="2" x2="8" y2="6"></line>
                          <line x1="3" y1="10" x2="21" y2="10"></line>
                        </svg>
                        <span>{new Date(featuredEvent.dateTime).toLocaleDateString()}</span>
                      </div>
                      <div className="advertise-event-location">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                          <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                        <span>{featuredEvent.location}</span>
                      </div>
                    </div>
                    <button className="advertise-event-button">Find Out More</button>
                  </div>
                </Link>
                </div>
              </div>
            ) : (
              <div className="no-events">
                <h2>No Upcoming Events</h2>
                <p>Check back soon for exciting new events!</p>
              </div>
            )}
          </div>
          <Link to="/events" className="view-all-events-button">View All Events</Link>
        </div>
      </section>
      <main className="landing-main">
        <section className="featured-coupons">
          <div className="content-wrapper">
            <h2>Featured Vouchers</h2>
            <p>Discover the best deals and discounts from local brands.</p>
            <div className="coupon-grid">
              {landingData && landingData.vouchers && landingData.vouchers.length > 0 ? (
                landingData.vouchers.map(voucher => (
                  <VoucherCard key={voucher._id} voucher={voucher} />
                ))
              ) : (
                <p>No vouchers available at the moment. Check back soon!</p>
              )}
            </div>
          </div>
        </section>

        <section className="join-community">
          <div className="content-wrapper">
            <h2>Join Our Community</h2>
            <p>Sign up now to start saving with our exclusive Vouchers. Get tickets to events and more!</p>
            <div className="auth-form-container">
              <div className="auth-form">
                <h3 className='auth-form-title'>Join today!</h3>
                <button onClick={handleGetStarted} className="create-account-button">Create account</button>
                <p className="terms-agreement">
                  By signing up, you agree to our <a href="#" onClick={handleOpenTerms} className="terms-link">terms & conditions</a>
                </p>
              </div>
              <div className="auth-divider">
                <span>or</span>
              </div>
              <div className="auth-form">
                <button onClick={handleLogin} className="sign-in-button">Sign in</button>
              </div>
            </div>
          </div>
        </section>

        <section className="our-partners">
          <div className="content-wrapper">
            <h2>Our Partners</h2>
            <p>We work with the best local brands and companies to bring you the latest deals and discounts.</p>
            <div className="partner-grid">
              {landingData && landingData.companies && landingData.companies.length > 0 ? (
                landingData.companies.map(company => (
                  <div key={company._id} className="partner-logo">
                    <img src={company.logoUrl || placeholderImage} alt={company.name} />
                  </div>
                ))
              ) : (
                <p>We're working on partnering with great local businesses. Stay tuned!</p>
              )}
            </div>
          </div>
        </section>

        <section className="how-it-works">
          <div className="content-wrapper">
            <h2>How it Works</h2>
            <p>Discover, save, and redeem Vouchers with our easy-to-use platform.</p>
            <div className="steps-grid">
              <div className="step">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="11" cy="11" r="8"></circle>
                  <path d="m21 21-4.3-4.3"></path>
                </svg>
                <h3>Find Coupons</h3>
                <p>Browse our collection of Vouchers from your local businesses.</p>
              </div>
              <div className="step">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z"></path>
                  <path d="M13 5v2"></path>
                  <path d="M13 17v2"></path>
                  <path d="M13 11v2"></path>
                </svg>
                <h3>Redeem Vouchers</h3>
                <p>Shows Vouchers to the staff to get your discounts.</p>
              </div>
              <div className="step">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M12.586 2.586A2 2 0 0 0 11.172 2H4a2 2 0 0 0-2 2v7.172a2 2 0 0 1 .586 1.414l8.704 8.704a2.426 2.426 0 0 0 3.42 0l6.58-6.58a2.426 2.426 0 0 0 0-3.42z"></path>
                  <circle cx="7.5" cy="7.5" r=".5" fill="currentColor"></circle>
                </svg>
                <h3>Save Money</h3>
                <p>Use the Vouchers to get the best deals on your purchases.</p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Suspense fallback={<div>Loading...</div>}>
        {showAuthPopup && (
          <AuthPopup
            mode={authMode}
            onClose={() => setShowAuthPopup(false)}
            onLoginSuccess={handleLoginSuccess}
          />
        )}
      </Suspense>

      {showTerms && (
        <div className="terms-overlay">
          <TermsAndConditions onBack={() => setShowTerms(false)} fromRegistration={false} />
        </div>
      )}
    </div>
  );
};

export default LandingPage;