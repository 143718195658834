import React from 'react';
import './QRCodePopup.css';
import {QRCodeSVG} from 'qrcode.react';

/**
 * Represents a popup component displaying a QR code for a ticket.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.ticket - The ticket object containing event details and QR code.
 * @param {Function} props.onClose - Function to close the popup.
 * @returns {React.ReactElement} A QR code popup element.
 */
const QRCodePopup = ({ ticket, onClose }) => {
  return (
    <div className="qr-code-popup-overlay" onClick={onClose}>
      <div className="qr-code-popup" onClick={(e) => e.stopPropagation()}>
        <button className="qr-close-button" onClick={onClose}>&times;</button>
        <h3>{ticket.eventTitle.substring(0, 80)}</h3>
        <QRCodeSVG value={ticket.publicId} size={200} />
        <p>Scan this QR code at the event entrance</p>
      </div>
    </div>
  );
};

export default QRCodePopup;