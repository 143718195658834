import imageCompression from 'browser-image-compression';

export async function compressAndConvertToWebP(file, maxSizeMB = 1, maxWidthOrHeight = 1920) {
  try {
    const compressedFile = await imageCompression(file, {
      maxSizeMB,
      maxWidthOrHeight,
      fileType: 'image/webp'
    });

    const webpFile = new File([compressedFile], `${file.name.split('.')[0]}.webp`, {
      type: 'image/webp'
    });

    return webpFile;
  } catch (error) {
    console.error('Error compressing and converting image:', error);
    throw error;
  }
}

export async function processMultipleImages(files, maxSizeMB = 1, maxWidthOrHeight = 1920) {
  return Promise.all(files.map(file => compressAndConvertToWebP(file, maxSizeMB, maxWidthOrHeight)));
}