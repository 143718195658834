import React, { useState, useEffect } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import api from '../../api';
import './EventListPage.css';
import Header from '../Header/Header';
/**
 * Represents the Event List Page component.
 * Displays a list of upcoming events and a featured event.
 *
 * @component
 * @returns {React.ReactElement} The Event List Page UI.
 */
function EventListPage() {
  const [events, setEvents] = useState([]);
  const [featuredEvent, setFeaturedEvent] = useState(null);
  const { user } = useOutletContext();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await api.getAllEvents();
        console.log('Fetched events:', response.data);
        if (Array.isArray(response.data)) {
          setEvents(response.data);
          if (response.data.length > 0) {
            setFeaturedEvent(response.data[0]);
          }
        } else {
          console.error('Unexpected response format:', response.data);
          setEvents([]);
        }
      } catch (error) {
        console.error('Error fetching events:', error);
        setEvents([]);
      }
    };
    fetchEvents();
  }, []);

  return (
    <div className="event-list-page">
      <Header user={user} />
      <main className="main-content">
        <section className="hero-section">
          <div className="container">
            <div className="hero-content">
              <h1 className="hero-title">Discover Extraordinary Events with Small Potatoes</h1>
              <p className="hero-description">
                Small Potatoes is your premier event platform, connecting you with unforgettable experiences. 
                From intimate gatherings to grand celebrations, we curate a diverse range of events to suit every taste. 
                Explore, book, and create memories with ease through our user-friendly platform.
              </p>
              <p className="hero-cta">
                Join our community of event enthusiasts and let Small Potatoes be your guide to the best experiences in town!
              </p>
            </div>
            {featuredEvent && (
              <div className="featured-event">
                <Link to={`/events/${featuredEvent.slug}`} className="featured-event-link">
                  <img src={featuredEvent.images[0]} alt={featuredEvent.title} className="featured-event-image" />
                  <div className="featured-event-content">
                    <h2 className="featured-event-title">{featuredEvent.title}</h2>
                    <p className="featured-event-description">{featuredEvent.shortDescription}</p>
                    <div className="event-details">
                      <div className="event-date">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                          <line x1="16" y1="2" x2="16" y2="6"></line>
                          <line x1="8" y1="2" x2="8" y2="6"></line>
                          <line x1="3" y1="10" x2="21" y2="10"></line>
                        </svg>
                        <span>{new Date(featuredEvent.dateTime).toLocaleDateString()}</span>
                      </div>
                      <div className="event-location">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                          <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                        <span>{featuredEvent.location}</span>
                      </div>
                    </div>
                    <button className="event-button">Find Out More</button>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </section>
        <section className="events-section">
          <div className="container">
            <h2 className="section-title">Upcoming Events</h2>
            <p className="section-description">
              Explore our curated selection of events and find your next adventure.
            </p>
            <div className="events-grid">
              {events.slice(1, 3).map(event => (
                <Link key={event._id} to={`/events/${event.slug}`} className="event-card">
                  <img src={event.images[0]} alt={event.title} className="event-image" />
                  <div className="featured-event-content">
                    <h2 className="featured-event-title">{event.title}</h2>
                    <p className="featured-event-description">{event.shortDescription}</p>
                    <div className="event-details">
                      <div className="event-date">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                          <line x1="16" y1="2" x2="16" y2="6"></line>
                          <line x1="8" y1="2" x2="8" y2="6"></line>
                          <line x1="3" y1="10" x2="21" y2="10"></line>
                        </svg>
                        <span>{new Date(event.dateTime).toLocaleDateString()}</span>
                      </div>
                      <div className="event-location">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                          <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                        <span>{event.location}</span>
                      </div>
                    </div>
                    <button className="event-button">Find Out More</button>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default EventListPage;
