import React, { useState, useEffect } from 'react';
import api from '../../../api';

/**
 * Manages card creation, editing, deletion, and assignment.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.cards - List of existing cards.
 * @param {Function} props.setCards - Function to update the cards list.
 * @returns {React.ReactElement} Card management interface.
 */
function CardManagement({ cards, setCards }) {
  const [newCard, setNewCard] = useState({ name: '', identificationNumber: '', expiryDate: '', isPaid: false, autoAssignToNewUsers: false });
  const [editingCard, setEditingCard] = useState(null);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleCardSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingCard) {
        const response = await api.updateCard(editingCard._id, newCard);
        setCards(cards.map(card => {
          if (card._id === editingCard._id) {
            return {
              ...response.data,
              vouchers: card.vouchers
            };
          }
          return card;
        }));
      } else {
        const response = await api.createCard(newCard);
        setCards([...cards, { ...response.data, vouchers: [] }]);
      }
      resetForm(); // Use the resetForm function here
    } catch (error) {
      console.error('Error submitting card:', error.response ? error.response.data : error);
      setError('Failed to submit card. Please try again.');
    }
  };

  const handleEditCard = (card) => {
    setEditingCard(card);
    setNewCard({
      name: card.name,
      identificationNumber: card.identificationNumber,
      expiryDate: card.expiryDate.split('T')[0],
      isPaid: card.isPaid,
      autoAssignToNewUsers: card.autoAssignToNewUsers
    });
  };

  const resetForm = () => {
    setEditingCard(null);
    setNewCard({ name: '', identificationNumber: '', expiryDate: '', isPaid: false, autoAssignToNewUsers: false });
  };

  const handleDeleteCard = async (cardId) => {
    if (window.confirm('Are you sure you want to delete this card? This action cannot be undone.')) {
      try {
        await api.deleteCard(cardId);
        setCards(cards.filter(card => card._id !== cardId));
      } catch (error) {
        console.error('Error deleting card:', error);
        setError('Failed to delete card. Please try again.');
      }
    }
  };

  const handleAssignCard = async (cardId, assignToAll) => {
    try {
      if (assignToAll) {
        await api.assignCardToUsers(cardId, null, true);
        setError('Card assigned to all users successfully');
      } else {
        if (!email) {
          setError('Please enter an email address');
          return;
        }
        await api.assignCardToUsers(cardId, email, false);
        setError('Card assigned to user successfully');
      }
    } catch (error) {
      console.error('Error assigning card:', error);
      setError(error.response?.data?.message || 'Failed to assign card. Please try again.');
    }
    setEmail('');
  };

  return (
    <div className="card-management">
      <div className="card-creation">
        <h2>{editingCard ? 'Edit Card' : 'Create New Card'}</h2>
        <form onSubmit={handleCardSubmit}>
          <input
            type="text"
            placeholder="Card Name"
            value={newCard.name}
            onChange={(e) => setNewCard({ ...newCard, name: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Identification Number"
            value={newCard.identificationNumber}
            onChange={(e) => setNewCard({ ...newCard, identificationNumber: e.target.value })}
            required
          />
          <input
            type="date"
            value={newCard.expiryDate}
            onChange={(e) => setNewCard({ ...newCard, expiryDate: e.target.value })}
            required
          />
          <label>
            <input
              type="checkbox"
              checked={newCard.isPaid}
              onChange={(e) => setNewCard({ ...newCard, isPaid: e.target.checked })}
            />
            Paid
          </label>
          <label>
            <input
              type="checkbox"
              checked={newCard.autoAssignToNewUsers}
              onChange={(e) => setNewCard({ ...newCard, autoAssignToNewUsers: e.target.checked })}
            />
            Auto-assign to new users
          </label>
          <button type="submit">{editingCard ? 'Update Card' : 'Create Card'}</button>
          {editingCard && (
            <button type="button" onClick={resetForm}>Cancel Edit</button>
          )}
        </form>
      </div>
      <div className="card-list">
        <h2>Existing Cards</h2>
        {Array.isArray(cards) && cards.length > 0 ? (
          cards.map(card => (
            <div key={card._id} className="card-item">
              <h3>{card.name}</h3>
              <p>ID: {card.identificationNumber}</p>
              <p>Expiry: {new Date(card.expiryDate).toLocaleDateString()}</p>
              <p>Paid: {card.isPaid ? 'Yes' : 'No'}</p>
              <h4>Vouchers:</h4>
              <ul>
                {card.vouchers && card.vouchers.map(voucher => (
                  <li key={voucher._id}>
                    {voucher.code} - {voucher.description}
                  </li>
                ))}
              </ul>
              <button onClick={() => handleEditCard(card)}>Edit</button>
              <button onClick={() => handleDeleteCard(card._id)}>Delete</button>
              <div className="assign-card">
                <h4>Assign Card to Users</h4>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter user email"
                />
                <button onClick={() => handleAssignCard(card._id, false)}>Assign to User</button>
                <button onClick={() => handleAssignCard(card._id, true)}>Assign to All Users</button>
              </div>
            </div>
          ))
        ) : (
          <p>No cards available. Create a new card to get started.</p>
        )}
      </div>
    </div>
  );
}

export default CardManagement;