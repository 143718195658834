import React, { useRef, useEffect } from 'react';
import './Basket.css';

const calculateBookingFee = (price) => {
  return Math.max(1, price * 0.1);
};

/**
 * Represents the basket component for displaying and managing selected items.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.items - The list of items in the basket.
 * @param {Function} props.updateQuantity - Function to update item quantity.
 * @param {Function} props.removeItem - Function to remove an item from the basket.
 * @param {Function} props.onCheckout - Function to handle checkout process.
 * @param {Function} props.onClose - Function to close the basket.
 * @param {Object} props.availableTickets - Object containing available ticket quantities.
 * @returns {React.ReactElement|null} The basket component or null if empty.
 */
const Basket = ({ items, updateQuantity, removeItem, onCheckout, onClose, availableTickets }) => {
  const basketRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (basketRef.current && !basketRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (items.length === 0) {
    return null;
  }

  const totalBeforeFees = items.reduce((sum, item) => sum + item.price * item.quantity, 0);
  const totalBookingFees = items.reduce((sum, item) => {
    const bookingFee = calculateBookingFee(item.price);
    return sum + bookingFee * item.quantity;
  }, 0);
  const total = totalBeforeFees + totalBookingFees;

  return (
    <div className="basket-popup">
      <div className="basket-popup-content" ref={basketRef}>
        <div className="basket-header">
          <h2 className="basket-title">Your Basket</h2>
          <button onClick={onClose} className="close-button">
            <svg className="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
        </div>
        <div className="basket-items">
          {items.map((item) => {
            const bookingFee = calculateBookingFee(item.price);
            const itemTotal = (item.price + bookingFee) * item.quantity;
            return (
              <div key={item.id} className="basket-item">
                <div className="basket-item-details">
                  <h3 className="basket-item-title">{item.title.substring(0, 100)}</h3>
                  <p className="basket-item-price">£{item.price.toFixed(2)}</p>
                </div>
                <div className="basket-item-actions">
                  <button 
                    onClick={() => updateQuantity(item.id, item.quantity - 1)}
                    disabled={item.quantity <= 1}
                    className="quantity-button"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M5 12h14" />
                    </svg>
                  </button>
                  <span className="quantity-value">{item.quantity}</span>
                  <button 
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                    disabled={item.quantity >= availableTickets[item.id] + item.quantity}
                    className="quantity-button"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M12 5v14" />
                      <path d="M5 12h14" />
                    </svg>
                  </button>
                  <button onClick={() => removeItem(item.id)} className="remove-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M3 6h18" />
                      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                    </svg>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="basket-summary">
          <div className="summary-item">
            <span>Subtotal</span>
            <span>£{totalBeforeFees.toFixed(2)}</span>
          </div>
          <div className="summary-item">
            <span>Booking Fees</span>
            <span>£{totalBookingFees.toFixed(2)}</span>
          </div>
          <div className="summary-total">
            <span>Total</span>
            <span>£{total.toFixed(2)}</span>
          </div>
        </div>
        <button onClick={onCheckout} className="checkout-button">
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
};

export default Basket;