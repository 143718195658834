import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './WorkWithUs.css';

function WorkWithUs() {
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'instant' });
  }, []);

  return (
    <div className="work-with-us" ref={topRef}>
      <h1>Partner with Small Potatoes</h1>
      <section className="intro">
        <p>Join our platform and revolutionize your digital card and voucher management!</p>
      </section>
      <section className="benefits">
        <h2>Benefits of Partnering with Us</h2>
        <ul>
          <li>Streamline your loyalty program management</li>
          <li>Increase customer engagement and retention</li>
          <li>Access to powerful analytics and insights</li>
          <li>Easy integration with your existing systems</li>
          <li>Dedicated support team to assist you</li>
        </ul>
      </section>
      <section className="how-it-works">
        <h2>How It Works</h2>
        <ol>
          <li>Contact us to discuss your needs and get a demo</li>
          <li>We'll work with you to customize the platform for your business</li>
          <li>Integration and onboarding process</li>
          <li>Launch your digital cards and vouchers on our platform</li>
          <li>Ongoing support and updates</li>
        </ol>
      </section>
      <section className="contact">
        <h2>Get Started</h2>
        <p>Ready to take your business to the next level? Contact us at <a href="mailto:contactus@smallpotatoestech.com">contactus@smallpotatoestech.com</a> to learn more about how we can work together.</p>
      </section>
      <Link to="/" className="back-link">Back to Home</Link>
    </div>
  );
}

export default WorkWithUs;
