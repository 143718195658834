import React from 'react';

/**
 * Represents a back button component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onClick - Function to handle click events on the back button.
 * @param {string} [props.className] - Additional CSS class for styling the button.
 * @returns {React.ReactElement} A back button element with an arrow icon.
 */
const BackButton = ({ onClick, className }) => (
  <button onClick={onClick} className={`back-button ${className || ''}`}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
      <polyline points="10 17 15 12 10 7"></polyline>
      <line x1="15" y1="12" x2="3" y2="12"></line>
    </svg>
  </button>
);

export default BackButton;