import React from 'react';
import './CardDisplay.css';
import QrCodeIcon from '../../images/qr.svg';

/**
 * Displays detailed information about a card.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.card - The card object to display.
 * @param {Function} props.onClose - Function to close the card display.
 * @returns {React.ReactElement} A card display element.
 */
function CardDisplay({ card, onClose }) {
  const formatExpiryDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <div className="card-display-overlay" onClick={onClose}></div>
      <div className="card-display" onClick={(e) => e.stopPropagation()}>
        <div className="card-display-header">     
          <button className="card-display-close-button" onClick={onClose}>×</button>
          <h2>My Card</h2>
        </div>
        <div className="card-display-content">
          <div className="card-display-info">
            <h3>About Cards</h3>
            <p>Cards are holders for vouchers, offering different bundles of discounts and deals.</p>
            {card.type === 'trial' && (
              <p className="card-display-trial-info">We've given you this card to have fun with as a valued user!</p>
            )}
          </div>
          <div className="card-display-card">
            <h4 className='card-display-name'>{card.name}</h4>
            <p className="card-display-number">{card.identificationNumber}</p>
            <h4 className="card-display-vouchers">{card.vouchers.length} vouchers</h4>
            <p className="card-display-available">available</p>
            <div className='card-display-qr-icon'>
              <img src={QrCodeIcon} alt="QR Code" />
            </div>
            <p className="card-display-valid-date">valid: {formatExpiryDate(card.expiryDate)}</p>
          </div>
          <div className="card-display-divider"></div>
          <div className="card-display-details">
            <h4>Card Details</h4>
            <p>Card Type: Trial{card.type}</p>
            <p>Card Description: A trial card to experience the app{card.description}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardDisplay;
