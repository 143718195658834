import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';
import api from '../../api';
import './HomePage.css';
import Header from '../Header/Header';
import AddToHomeScreenPrompt from '../AddToHome/AddToHomeScreenPrompt';
import VoucherDisplay from '../VoucherDisplay/VoucherDisplay';
import AuthPopup from '../AuthPopup/AuthPopup';
import placeholderImage from '../../images/placeholder.webp';
import placeholderLogo from '../../images/mcdonalds-logo.png';
import crownSvg from '../../images/crown.svg';
import CardDisplay from '../CardDisplay/CardDisplay';
import QrCodeIcon from '../../images/qr.svg';
import TicketCard from '../TicketCard/TicketCard';
import QRCodePopup from '../QRCodePopup/QRCodePopup';

/**
 * Represents a voucher card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.voucher - The voucher object to display.
 * @param {Function} props.onClick - Function to handle click events.
 * @param {string} [props.ribbonType] - Type of ribbon to display (e.g., 'vip', 'trial').
 * @returns {React.ReactElement} A voucher card element.
 */
const VoucherCard = ({ voucher, onClick, ribbonType }) => {
  return (
    <div className="voucher-card" onClick={onClick}>
      <div className="voucher-image">
        <img 
          src={voucher.backgroundImageUrl || placeholderImage} 
          alt="Voucher background" 
        />
        <div className="company-logo">
          <img 
            src={voucher.company && voucher.company.logoUrl ? voucher.company.logoUrl : placeholderLogo} 
            alt={`${voucher.company ? voucher.company.name : 'Company'} logo`} 
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = placeholderLogo;
            }}
          />
        </div>
      </div>
      <div className="voucher-content">
        <h4>{voucher.description}</h4>
        <p className="discount">{voucher.discount}% OFF</p>
        <p className="details">placeholder text for vouchers details.</p>
      </div>
      {ribbonType && (
        <div className={`voucher-ribbon ${ribbonType}`}>
          {ribbonType === 'vip' && <img src={crownSvg} alt="VIP Crown" className="vip-crown" />}
          <span>{ribbonType.toUpperCase()}</span>
        </div>
      )}
    </div>
  );
};

/**
 * Represents the home page component of the application.
 *
 * @component
 * @returns {React.ReactElement} The home page UI with user's cards, tickets, and vouchers.
 */
function HomePage() {
  const { user, handleLogout } = useOutletContext();
  const navigate = useNavigate();
  const { t } = useTranslation();


  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await api.getUserTickets();
        setTickets(response.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();
  }, []);

  useEffect(() => {
    if (!user) {
      navigate('/landing');
    }
  }, [user, navigate]);

  if (!user) {
    return null; // or a loading indicator
  }

  const [cards, setCards] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [activeTab, setActiveTab] = useState('cards');

  useEffect(() => {
    fetchUserCards();
  }, []);

  useEffect(() => {
    if (selectedVoucher || selectedCard) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.pageYOffset}px`;
      document.body.style.width = '100%';
    } else {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('top');
      document.body.style.removeProperty('width');
      window.scrollTo(0, parseInt(document.body.style.top || '0') * -1);
    }
  }, [selectedVoucher, selectedCard]);

  const fetchUserCards = async () => {
    try {
      const response = await api.get('/cards');
      setCards(response.data);
      fetchVouchersForCards(response.data);
    } catch (error) {
      console.error('Error fetching user cards:', error);
    }
  };

  const fetchVouchersForCards = async (cards) => {
    try {
      const voucherPromises = cards.map(card => api.getCardVouchers(card._id));
      const voucherResponses = await Promise.all(voucherPromises);
      const allVouchers = voucherResponses.flatMap(response => 
        response.data.map(voucher => ({
          ...voucher,
          ribbonType: determineRibbonType(voucher),
          company: voucher.company // Ensure company information is included
        }))
      );
      setVouchers(allVouchers);
    } catch (error) {
      console.error('Error fetching vouchers:', error);
    }
  };

  // Add this function to determine the ribbon type based on voucher and card properties
  const determineRibbonType = (voucher) => {
    if (voucher.isVIP) return 'vip';
    if (voucher.isTrial) return 'trial';
    return 'trial';
  };

  const handleVoucherClick = (voucher, event) => {
    event.preventDefault();
    setSelectedVoucher(voucher);
  };

  const handleCloseVoucherDisplay = () => {
    setSelectedVoucher(null);
  };

  const formatExpiryDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleCloseCardDisplay = () => {
    setSelectedCard(null);
  };

  const handleCloseAuthPopup = () => {
    setShowAuthPopup(false);
    navigate('/landing');
  };

  const handleLoginSuccess = () => {
    setShowAuthPopup(false);
    navigate('/');
  };

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
  };

  const handleCloseQRCodePopup = () => {
    setSelectedTicket(null);
  };

  function ProfileIcon() {
    return (
      <svg width="96" height="96" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
        <circle cx="12" cy="7" r="4" />
      </svg>
    )
  }

  return (
    <div className="home-page">
      <Header user={user} />
      <main className="profile-main">
        <div className="avatar">
          <ProfileIcon />
        </div>
        <h3 className="user-name">{user.name || 'Christopher Foulkes'}</h3>
      </main>
      <div className="cards-section-background">
        <div className="cards-section">
          <div className="cards-header">
            <button 
              className={`cards-tab ${activeTab === 'cards' ? 'active' : ''}`}
              onClick={() => setActiveTab('cards')}
            >
              {t.yourCards}
            </button>
            <button 
              className={`cards-tab ${activeTab === 'tickets' ? 'active' : ''}`}
              onClick={() => setActiveTab('tickets')}
            >
              {t.tickets}
            </button>
          </div>
          <div className="cards-content">
            <h3 className="cards-title">{activeTab === 'cards' ? t.yourCards : t.tickets}</h3>
            {activeTab === 'cards' && (
              Array.isArray(cards) && cards.length > 0 ? (
                cards.map(card => (
                  <div key={card._id} className="card" onClick={() => handleCardClick(card)}>
                    <h4 className='card-name'>{card.name}</h4>
                    <p className="card-number">{card.identificationNumber}</p>
                    <h4 className="vouchers">{card.vouchers.length} vouchers</h4>
                    <p className="available">available</p>
                    <div className='qr-code-icon'>
                      <img src={QrCodeIcon} alt="QR Code" />
                    </div>
                    <p className="valid-date">valid: {formatExpiryDate(card.expiryDate)}</p>
                  </div>
                ))
              ) : (
                <p>{t.noCards}</p>
              )
            )}
            {activeTab === 'tickets' && (
              <div className="tickets-list">
                {tickets.length > 0 ? (
                  tickets.map(ticket => {
                    // Check if ticket and its nested properties exist before accessing them
                    if (ticket) {
                      return (
                        <TicketCard
                          key={ticket._id}
                          ticket={{
                            eventTitle: ticket.eventTitle,
                            eventDate: ticket.eventDate,
                            ticketType: ticket.ticketType.title || 'Unknown Type',
                            ticketTypeBadge: ticket.ticketType.badge || 'Unknown Badge',
                            status: ticket.status || 'Unknown'
                          }}
                          onClick={() => handleTicketClick(ticket)}
                        />
                      );
                    }
                    return null; // Skip rendering this ticket if data is incomplete
                  })
                ) : (
                  <p>{t.noTickets}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {activeTab === 'cards' && (
        <div className="vouchers-section">
          <h2 className='vouchers-title'>{t.vouchers}</h2>
          <div className="voucher-list">
            {vouchers.length > 0 ? (
              vouchers.map(voucher => (
                <VoucherCard 
                  key={voucher._id} 
                  voucher={voucher} 
                  onClick={(event) => handleVoucherClick(voucher, event)}
                  ribbonType={voucher.ribbonType}
                />
              ))
            ) : (
              <p>{t.noVouchers}</p>
            )}
          </div>
        </div>
      )}
      <div className="coming-soon-section-background">
        {activeTab === 'cards' && (
          <div className="coming-soon-section">
            <div className="coming-soon-icon">🚀</div>
            <p className="coming-soon-text">{t.moreVouchersComingSoon}</p>
          </div>
        )}

        {activeTab === 'tickets' && (
          <div className="coming-soon-section">
            <div className="coming-soon-icon">🎉</div>
            <p className="coming-soon-text">{t.moreEventsComingSoon}</p>
          </div>
        )}
      </div>
      {selectedVoucher && (
        <VoucherDisplay 
          voucher={selectedVoucher} 
          onClose={handleCloseVoucherDisplay}
        />
      )}

      {selectedCard && (
        <CardDisplay 
          card={selectedCard} 
          onClose={handleCloseCardDisplay}
        />
      )}

      {selectedTicket && (
        <QRCodePopup
          ticket={selectedTicket}
          onClose={handleCloseQRCodePopup}
        />
      )}

      <AddToHomeScreenPrompt />
      {user.isAdmin && (
        <div className="admin-link">
          <a href="/admin">{t.adminDashboard}</a>
        </div>
      )}
        
        {showAuthPopup && (
        <AuthPopup
          onClose={handleCloseAuthPopup}
          onLoginSuccess={handleLoginSuccess}
        />
      )}
    </div>
  );
}

export default HomePage;