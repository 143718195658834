import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './AboutUs.css';

/**
 * Represents the About Us page component.
 * 
 * @component
 * @returns {React.ReactElement} The About Us page content.
 */
function AboutUs() {
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'instant' });
  }, []);

  return (
    <div className="about-us" ref={topRef}>
      <h1>About Small Potatoes</h1>
      <section className="mission">
        <h2>Our Mission</h2>
        <p>At Small Potatoes, we're on a mission to revolutionize the way businesses manage digital cards and vouchers. We believe in creating simple, effective solutions that empower both businesses and customers.</p>
      </section>
      <section className="story">
        <h2>Our Story</h2>
        <p>Founded in 2023, Small Potatoes grew from a simple idea: that managing loyalty programs and promotional offers shouldn't be complicated. Our team of dedicated professionals has worked tirelessly to create a platform that's both powerful and user-friendly.</p>
      </section>
      <section className="values">
        <h2>Our Values</h2>
        <ul>
          <li><strong>Innovation:</strong> We're always looking for new ways to improve our services.</li>
          <li><strong>Simplicity:</strong> We believe in making complex processes simple and accessible.</li>
          <li><strong>Customer-Centric:</strong> Our customers' needs are at the heart of everything we do.</li>
          <li><strong>Integrity:</strong> We're committed to honest, transparent business practices.</li>
        </ul>
      </section>
      <section className="team">
        <h2>Our Team</h2>
        <p>Small Potatoes is powered by a diverse team of experts in technology, business, and customer service. Together, we're working to create the best possible experience for our users.</p>
      </section>
      <Link to="/" className="back-link">Back to Home</Link>
    </div>
  );
}

export default AboutUs;