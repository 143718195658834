import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LanguageProvider } from './Translations/LanguageContext';
import './app.css';
import './GlobalStyles.css';  // Add this line
import ErrorBoundary from './Catch/ErrorBoundary';
import routes from './Routes/routes';

const router = createBrowserRouter(routes);

/**
 * Main application component.
 * 
 * @component
 * @returns {React.ReactElement} The root application element wrapped with necessary providers.
 */
function App() {
  return (
    <ErrorBoundary>
      <LanguageProvider>
        <RouterProvider router={router} />
      </LanguageProvider>
    </ErrorBoundary>
  );
}

export default App;