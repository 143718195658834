import React from 'react';
import './LoadingBar.css';

/**
 * Represents a loading bar component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.progress - The progress percentage of the loading bar.
 * @returns {React.ReactElement} A loading bar element.
 */
const LoadingBar = ({ progress }) => {
  return (
    <div className="loading-bar">
      <div className="loading-bar-progress" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default LoadingBar;