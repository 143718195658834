import React, { useState, useEffect } from 'react';
import getStripePromise from '../../../../Config/stripeConfig';
import api from '../../../../api';
import './Checkout.css';
import { useTranslation } from '../../../../hooks/useTranslation';

const calculateBookingFee = (price) => {
	return Math.max(1, price * 0.1);
};

/**
 * Validates the items in the basket.
 *
 * @param {Array} items - The list of items to validate.
 * @returns {boolean} True if all items are valid, false otherwise.
 */
const validateItems = (items) => {
	return items.every(item => 
		item.title && 
		typeof item.quantity === 'number' && item.quantity > 0 &&
		typeof item.price === 'number' && item.price >= 0
	);
};

/**
 * Represents the checkout component for processing payments.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.total - The total amount to be paid.
 * @param {Array} props.items - The list of items being purchased.
 * @param {Object} props.event - The event details.
 * @returns {React.ReactElement} The checkout component.
 */
const Checkout = ({ total, items, event }) => {
	const { t } = useTranslation();

	const [error, setError] = useState(null);
	const [stripePromise, setStripePromise] = useState(null);
	const [isProcessing, setIsProcessing] = useState(false);
	const [isLoadingStripe, setIsLoadingStripe] = useState(true);
	const [referralCode, setReferralCode] = useState(null);

	useEffect(() => {
		const loadStripe = async () => {
			setIsLoadingStripe(true);
			try {
				const stripe = await getStripePromise();
				setStripePromise(stripe);
			} catch (err) {
				console.error('Failed to load Stripe:', err);
				setError('Failed to load payment system. Please try again later.');
			} finally {
				setIsLoadingStripe(false);
			}
		};
		loadStripe();
	}, []);

	useEffect(() => {
		const storedReferralCode = localStorage.getItem('referralCode');
		if (storedReferralCode) {
			setReferralCode(storedReferralCode);
			localStorage.removeItem('referralCode'); // Clear the stored code
		}
	}, []);

	const handleCheckout = async () => {
		if (isProcessing || !stripePromise) {
			setError('Unable to process payment at this time. Please try again later.');
			return;
		}
		setIsProcessing(true);
		try {
			setError(null);

			const userString = localStorage.getItem('user');
			if (!userString) {
				throw new Error('User not found. Please log in.');
			}

			let userId;
			try {
				const user = JSON.parse(userString);
				userId = user.id;
				if (!userId) {
					throw new Error('User ID not found');
				}
			} catch (error) {
				console.error('Error parsing user data:', error);
				throw new Error('Invalid user data. Please log in again.');
			}

			if (!validateItems(items)) {
				throw new Error('Invalid item data');
			}

			const response = await api.createCheckoutSession({
				eventId: event._id, 
				tickets: items.map(item => ({
					ticketType: item.title,
					quantity: item.quantity,
					price: item.price
				})),
				userId: userId,
				referralCode: referralCode // Add referral code to the request
			});

			console.log("Response from server:", response);

			if (!response.data || !response.data.id) {
				throw new Error('Invalid response from server');
			}

			// Redirect to Stripe Checkout
			const stripe = await stripePromise;
			const result = await stripe.redirectToCheckout({
				sessionId: response.data.id
			});

			if (result.error) {
				throw new Error(result.error.message);
			}

		} catch (err) {
			console.error('Checkout error:', err);
			setError(err.message || 'An unexpected error occurred during checkout');
		} finally {
			setIsProcessing(false);
		}
	};

	if (isLoadingStripe) {
		return <div className="loading">Loading payment system...</div>;
	}

	if (error) {
		return (
			<div className="error-container">
				<p className="error">{error}</p>
				<button onClick={() => window.location.reload()}>Retry</button>
			</div>
		);
	}

	const totalBeforeFees = items.reduce((sum, item) => sum + item.price * item.quantity, 0);
	const totalBookingFees = items.reduce((sum, item) => {
		const bookingFee = calculateBookingFee(item.price);
		return sum + bookingFee * item.quantity;
	}, 0);
	const totalWithFees = totalBeforeFees + totalBookingFees;

	return (
		<div className="checkout-container">
			<h2 className="checkout-title">Checkout</h2>
			<h4>{t.currencyConversions}</h4>
			<div className="checkout-summary">
				<h3>Order Summary</h3>
				{items.map((item, index) => {
					const bookingFee = calculateBookingFee(item.price);
					const itemTotal = (item.price + bookingFee) * item.quantity;
					return (
						<div key={index} className="checkout-item">
							<span className="checkout-item-title">{item.title}</span>
							<span className="checkout-item-quantity">x{item.quantity}</span>
							<div className="checkout-item-price">
								<div>Price: £{item.price.toFixed(2)}</div>
								<div>Booking Fee: £{bookingFee.toFixed(2)}</div>
								<div>Total: £{itemTotal.toFixed(2)}</div>
							</div>
						</div>
					);
				})}
				<div className="checkout-total">
					<div>Subtotal: £{totalBeforeFees.toFixed(2)}</div>
					<div>Total Booking Fees: £{totalBookingFees.toFixed(2)}</div>
					<strong>Total: £{totalWithFees.toFixed(2)}</strong>
				</div>
			</div>
			<button className="checkout-button" onClick={handleCheckout} disabled={!stripePromise || isProcessing}>
				{isProcessing ? 'Processing...' : `Pay £${totalWithFees.toFixed(2)}`}
			</button>
		</div>
	);
};

export default Checkout;