import React, { useEffect } from 'react';
import './TermsAndConditions.css';
import BackButton from '../Ui/BackButton';

/**
 * Represents the Terms and Conditions component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onBack - Function to handle going back.
 * @param {boolean} props.fromRegistration - Whether accessed from registration.
 * @returns {React.ReactElement} A Terms and Conditions popup element.
 */
function TermsAndConditions({ onBack, fromRegistration }) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('terms-popup-overlay')) {
      onBack();
    }
  };

  return (
<div className="terms-popup-overlay" onClick={handleOverlayClick}>
    <div className="terms-popup">
      <div className="terms-content">
      <BackButton onClick={onBack} className="terms-back-button" />
      <h2>Terms and Conditions</h2>
      <h3>Terms of Service for Small Potatoes</h3>
      <p>Last updated: 26/08/2024</p>

      <h4>1. Introduction</h4>
      <p>Welcome to Small Potatoes. These Terms of Service ("Terms") govern your use of our login system and associated services. By using our services, you agree to these Terms and our Privacy Policy.</p>

      <h4>2. GDPR Compliance</h4>
      <p>We are committed to protecting your personal data and complying with the General Data Protection Regulation (GDPR). This section outlines how we handle your data in accordance with GDPR principles.</p>

      <h4>3. Data We Collect</h4>
      <p>We collect and process the following personal data:</p>
      <ul>
        <li>Name</li>  
        <li>Home City</li>
        <li>Phone Number (stored in a secured fashion)</li> 
        <li>Email address</li>
        <li>Password (stored in a securely hashed format)</li>
      </ul>

      <h4>4. Purpose of Data Collection</h4>
      <p>We collect this data for the following purposes:</p>
      <ul>
        <li>To create and manage your user account</li>
        <li>To authenticate you when you log in</li>
        <li>To communicate with you about your account and our services</li>
        <li>To provide you with the services offered through our platform</li>
      </ul>

      <h4>5. Legal Basis for Processing</h4>
      <p>We process your data based on the following legal grounds:</p>
      <ul>
        <li>Performance of a contract: To provide you with our services</li>
        <li>Consent: Where you have given explicit consent for specific processing activities</li>
        <li>Legitimate interests: Where processing is necessary for our legitimate interests, and not overridden by your rights</li>
      </ul>

      <h4>6. Data Retention</h4>
      <p>We will retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

      <h4>7. Your Rights Under GDPR</h4>
      <p>You have the following rights regarding your personal data:</p>
      <ul>
        <li>Right to access</li>
        <li>Right to rectification</li>
        <li>Right to erasure ("right to be forgotten")</li>
        <li>Right to restrict processing</li>
        <li>Right to data portability</li>
        <li>Right to object</li>
        <li>Rights related to automated decision-making and profiling</li>
      </ul>
      <p>To exercise these rights, please contact us at [contactus@smallpotatoestech.com].</p>

      <h4>8. Data Security</h4>
      <p>We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, including:</p>
      <ul>
        <li>Encryption of personal data</li>
        <li>Regular testing and evaluation of the effectiveness of security measures</li>
        <li>Measures to ensure the ongoing confidentiality, integrity, availability, and resilience of processing systems</li>
      </ul>

      <h4>9. Data Breaches</h4>
      <p>In the case of a personal data breach, we will notify the relevant supervisory authority within 72 hours of becoming aware of the breach, where feasible. If the breach is likely to result in a high risk to your rights and freedoms, we will also notify you without undue delay.</p>

      <h4>10. International Data Transfers</h4>
      <p>If we transfer your personal data outside the European Economic Area (EEA), we will ensure that appropriate safeguards are in place to protect your data.</p>

      <h4>11. User Registration</h4>
      <p>You must provide accurate and complete information when registering for an account. You are responsible for maintaining the confidentiality of your account and password.</p>

      <h4>12. User Conduct</h4>
      <p>You agree not to use Small Potatoes for any unlawful purpose or in any way that interrupts, damages, or impairs the service. You are solely responsible for your interactions with other users of the service.</p>

      <h4>13. Intellectual Property</h4>
      <p>All content on Small Potatoes, including text, graphics, logos, and software, is the property of Small Potatoes and protected by copyright laws. You may not use, reproduce, or distribute any content from our service without our express written permission.</p>

      <h4>14. Limitation of Liability</h4>
      <p>Small Potatoes shall not be liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of the service. Our liability is limited to the maximum extent permitted by law.</p>

      <h4>15. Modifications to Terms</h4>
      <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page and updating the "Last updated" date. Your continued use of Small Potatoes after changes constitutes acceptance of those changes.</p>

      <h4>16. Governing Law</h4>
      <p>These Terms shall be governed by and construed in accordance with the laws of the United Kingdom.</p>

      <h4>17. Contact Us</h4>
      <p>If you have any questions about these Terms or our data practices, please contact us at:</p>
      <p>smallpotatoes<br />[contactus@smallpotatoestech.com]</p>
      </div>
    </div>
  </div>
  );
}

export default TermsAndConditions;