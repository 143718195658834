import React, { createContext, useState, useContext, useEffect } from 'react';

const LanguageContext = createContext();

/**
 * Provides language context for the application.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the provider.
 * @returns {React.ReactElement} A context provider for language settings.
 */

function getDefaultLanguage() {
  const savedLanguage = localStorage.getItem('language');
  if (savedLanguage) {
    return savedLanguage;
  }

  const browserLanguages = navigator.languages || [navigator.language || navigator.userLanguage];
  
  for (let lang of browserLanguages) {
    lang = lang.substr(0, 2).toLowerCase();
    if (lang === 'zh') {
      return 'zh';
    }
    if (lang === 'en') {
      return 'en';
    }
  }

  return 'en'; // Default to English if no match
}

/**
 * Custom hook to access the language context.
 *
 * @function
 * @returns {Object} An object containing the current language and a function to set the language.
 * @throws {Error} If used outside of a LanguageProvider.
 */
export function LanguageProvider({ children }) {
  const [language, setLanguageState] = useState(getDefaultLanguage);

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const setLanguage = (newLanguage) => {
    setLanguageState(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}
