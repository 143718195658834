import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './ContactUs.css';

/**
 * Represents the Contact Us page component.
 *
 * @component
 * @returns {React.ReactElement} The Contact Us page content.
 */
function ContactUs() {
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'instant' });
  }, []);

  return (
    <div className="contact-us" ref={topRef}>
      <h1>Contact Us</h1>
      <p>If you have any questions or concerns, please don't hesitate to reach out to us:</p>
      <div className="contact-info">
        <p><strong>Email:</strong> <a href="mailto:contactus@smallpotatoestech.com">contactus@smallpotatoestech.com</a></p>
      </div>
      <div className="description">
        <h2>About Small Potatoes</h2>
        <p>Small Potatoes is dedicated to providing innovative solutions for managing digital cards and vouchers. Our platform helps businesses streamline their loyalty programs and promotional offers, while offering customers a convenient way to access and use their rewards.</p>
        <p>We're committed to excellent customer service and are always here to assist you with any inquiries or support you may need.</p>
      </div>
      <Link to="/" className="back-link">Back to Home</Link>
    </div>
  );
}

export default ContactUs;
