import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation, Navigate, useOutletContext } from 'react-router-dom';
import { useLanguage } from '../Translations/LanguageContext';
import Footer from '../components/Footer/Footer';
import api, { fetchCSRFToken } from '../api';

/**
 * Protects routes that require authentication.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render if authenticated.
 * @param {string} props.redirectTo - The path to redirect to if not authenticated.
 * @returns {React.ReactElement} The protected route or a redirect.
 */
export const ProtectedRoute = ({ children, redirectTo }) => {
  const { user, isLoading } = useOutletContext();
  
  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  return user ? children : <Navigate to={redirectTo} />;
};

/**
 * Main content component for the application.
 * Handles authentication, routing, and provides context to child components.
 *
 * @component
 * @returns {React.ReactElement} The main application content.
 */
function AppContent() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await fetchCSRFToken();
        console.log('Checking authentication...');
        const authResponse = await api.checkAuth();
        if (authResponse.data.isAuthenticated) {
          setUser(authResponse.data.user);
          if (authResponse.data.user.isOrganizer) {
            const organizedEvents = await api.getOrganizedEvents();
            setUser(prevUser => ({ ...prevUser, organizedEvents: organizedEvents.data }));
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Auth check error:', error);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    if (!isLoading && !user) {
      const publicRoutes = ['/landing', '/events', '/settings', '/forgot-password', '/reset-password', '/verify-ticket', '/contact', '/about', '/work-with-us'];
      const isPublicRoute = publicRoutes.some(route => location.pathname.startsWith(route));
      
      if (!isPublicRoute) {
        navigate('/landing');
      }
    }
  }, [user, isLoading, navigate, location.pathname]);

  const handleLoginSuccess = (userData) => {
    setUser(userData);  
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <main className="main-content">
        <Outlet context={{
          user,
          setUser,
          handleLoginSuccess,
          handleLogout: async () => {
            await api.logout();
            localStorage.removeItem('user');
            localStorage.removeItem('token'); 
            setUser(null);
            navigate('/landing');
          },
          language,
          setLanguage,
          isLoading
        }} />
      </main>
      <Footer />
    </div>
  );
}

export default AppContent;